<template>
  <div>
    <b-table-simple bordered responsive v-if="!evaluatee_view">
      <b-thead>
        <b-tr>
          <b-th style="width: 50%">Ítem</b-th>
          <b-th style="width: 50%">Clave</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="question in questions_list"
          :key="
            'correct_answer' +
            question.id +
            '-' +
            question.segment_order +
            '-' +
            question.order
          "
        >
          <b-td>
            <div class="d-flex">
              <span class="mr-1">
                <span v-if="question.segment_order != null">
                  {{ question.segment_order }}.
                </span>
                {{ question.order }}.
              </span>
              <div v-html="question.title" class="rich-text-content"></div>
            </div>
          </b-td>
          <b-td>
            <div v-if="question.question_type == 1">
              <div
                v-html="question.proposed_answer"
                class="rich-text-content"
              ></div>
            </div>
            <div v-else-if="question.question_type == 2">
              <div>
                {{ multipleChoiceAnswer(question) }}
              </div>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";

export default {
  name: "CorrectAnswerComponent",
  props: {
    questions_list: {
      type: Array,
    },
    evaluatee_view: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      multiple_choice_question_options:
        names.NEW_MULTIPLE_CHOICE_QUESTION_OPTIONS,
    }),
  },
  methods: {
    multipleChoiceAnswer(question) {
      return this.multiple_choice_question_options
        .filter((x) => x.multiple_choice_question == question.id && x.score > 0)
        .map((y) => String.fromCharCode(96 + y.order))
        .join(", ");
    },
  },
  watch: {},
  mounted() {},
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
th {
  color: white;
  font-weight: bold;
  background-color: var(--kl-menu-color) !important;
}
</style>